import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNumber, get, orderBy } from 'lodash';
import { AssessmentType } from '../models/settings.model';
import { getCandidateDetails } from '../thunks/candidates.thunks';
import { getClientsConfig, getClientsExternalConfig } from '../thunks/client.thunk';
import {
  getAllSettings,
  getDepartmentsOptions,
  setPositionsOptions,
  setRecruitersOptions,
  setDepartmentsOptions,
  setClientsOptions,
  setPipelineTemplates,
  setPipelineSteps,
  setTagsOptions,
  setConfigOptions,
  setRefappQuestionnaires,
  setAmsGroups,
  setAlvaLabsProfiles,
  setCalendarDetails,
  setCandidatesFilter,
  getPageSettingsThunk,
  setHubertInterviewTemplates,
} from '../thunks/settings.thunks';
import { IFont } from '../../interfaces/IFont';
import { t } from 'i18next';

type AssessmentTypeOption = {
  id: number;
  name: string;
  value: number;
  label: string;
};

type ConfigOption = {
  name: string;
  code: string;
  label: string;
  value: string;
};

type ExternalConfigOption = {
  id: string;
  label: string;
};

type SyncedRecipientOption = {
  label: string;
  value: string;
};

type Option = {
  label: string;
  value: string | number;
};

type Template = {
  id: number;
  name: string;
};

type CandidateActive = {
  loading: boolean;
  data?: unknown;
};

type Candidates = {
  active: CandidateActive;
  data: unknown[];
  unchecked: unknown[];
  selected: unknown[];
  selectAll: boolean;
  reject: boolean;
  totalCount: number;
  rejected: number;
  tab: string;
  fromPosition: boolean;
  fromRedirect: boolean;
  previousCandidate: object | null;
};

type Position = {
  id: number | null;
  name: string | null;
  templates: Template[];
  activePipelineStepId?: number;
  isSet: boolean;
};

type PipelineStep = {
  id: number;
  name: string;
  value: number;
  label: string;
};

interface Occupation {
  name: string;
  id: number;
}

interface Group {
  name: string;
  occupations: Occupation[];
}

interface OccupationGroups {
  groups: Group[];
  name: string;
}

interface Municipality {
  municipalityID: number;
  nationalNUTSLAU2Code: string;
  nationalNUTSLevel3Code: string;
  term: string;
}

type Options = {
  positions: Option[];
  recruiters: Option[];
  steps: PipelineStep[];
  templates: Template[];
  tags: Option[];
  clients: Option[];
  departments: Option[];
  tests: AssessmentTypeOption[];
  clientTypes: ConfigOption[];
  companyIndustries: ConfigOption[];
  jobFunctions: ConfigOption[];
  alvaLabsProfiles: ExternalConfigOption[];
  hubertInterviewTemplates: ExternalConfigOption[];
  refappQuestionnaires: ExternalConfigOption[];
  syncedRecipientList: SyncedRecipientOption[] | null;
  municipalities: Municipality[];
  amsGroups: OccupationGroups[];
};

type Filters = {
  candidates: {
    status: 'Active';
    positionId?: number;
    pipelineStepId?: number;
    rejected?: boolean;
  };
  position: {};
};

type InitialState = {
  locale: string;
  position: Position;
  menu: {
    minimized: boolean;
  };
  table: {
    pageSize: number;
  };
  products: {
    lastViewed: string | null;
  };
  options: Options;
  candidates: Candidates;
  sorts: {
    candidates: string;
  };
  filters: Filters;
  careerPage: {
    addingBlock: boolean;
    upperBlock: null;
    pageSettings: {
      loading: boolean;
      generalStyling: {
        useCustomFonts: boolean;
        header: IFont;
        subTitle: IFont;
        body: IFont;
      };
    };
  };
  positionsEdited: unknown[];
  calendar: unknown | null;
  loading: boolean;
};

const defaultTests = [
  { id: 1, name: 'MINT' },
  { id: 2, name: 'SERVICE_FIRST' },
] as AssessmentType[];

interface AmsGroupsPayload {
  municipalities: Municipality[];
  occupationTree: OccupationGroups[];
}

const createTests = (assessmentTypes: AssessmentType[]): AssessmentTypeOption[] => {
  return (assessmentTypes.length < 2 ? assessmentTypes : defaultTests)
    .filter((item) => (item.name || '').toLowerCase() !== 'refapp')
    .map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
};

const createArrayFromConfigOptions = (arr: { name: string; code: string }[]) => {
  return orderBy(
    (arr || []).map((item) => {
      return {
        label: item.name,
        value: item.code,
      };
    })
  );
};

export const initialState: InitialState = {
  locale: 'en',
  position: {
    id: null,
    name: null,
    templates: [],
    isSet: false,
  },
  menu: {
    minimized: false,
  },
  table: {
    pageSize: 20,
  },
  products: {
    lastViewed: null,
  },
  options: {
    positions: [],
    recruiters: [],
    steps: [],
    templates: [],
    tags: [],
    clients: [],
    departments: [],
    tests: createTests(defaultTests),
    clientTypes: [],
    companyIndustries: [],
    jobFunctions: [],
    alvaLabsProfiles: [],
    hubertInterviewTemplates: [],
    refappQuestionnaires: [],
    syncedRecipientList: null,
    amsGroups: [],
    municipalities: [],
  },
  candidates: {
    active: {
      loading: false,
    },
    data: [],
    unchecked: [],
    selected: [],
    selectAll: false,
    reject: false,
    totalCount: 0,
    rejected: 0,
    tab: 'Timeline',
    fromPosition: true,
    fromRedirect: false,
    previousCandidate: null,
  },
  sorts: {
    candidates: '-DateOfApplication',
  },
  filters: {
    candidates: {
      status: 'Active',
      rejected: false,
    },
    position: {},
  },
  careerPage: {
    addingBlock: false,
    upperBlock: null,
    pageSettings: {
      loading: false,
      generalStyling: {
        useCustomFonts: false,
        header: {
          family: '',
          weight: 100,
          size: '16px',
        },
        subTitle: {
          family: '',
          weight: 100,
          size: '16px',
        },
        body: {
          family: '',
          weight: 100,
          size: '16px',
        },
      },
    },
  },
  positionsEdited: [],
  calendar: null,
  loading: true,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    resetAllSettings: () => {
      return initialState;
    },
    setCandidatesTab: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        candidates: {
          ...state.candidates,
          tab: payload,
        },
      };
    },
    setActivePipelineStepId: (state, { payload }: PayloadAction<{ activePipelineStepId: number }>) => {
      if (payload && payload.activePipelineStepId) {
        return {
          ...state,
          position: {
            ...state.position,
            activePipelineStepId: payload.activePipelineStepId,
          },
        };
      }

      return state;
    },
    // @ts-ignore
    setActivePosition: (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        position: {
          ...payload,
          id: payload.id, // not sure if there's other implementation using id.
          value: payload.id,
          label: payload.name,
          activePipelineStepId: null,
        },
      };
    },
    toggleMenuState: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        menu: {
          ...state.menu,
          minimized: payload,
        },
      };
    },
    setLocale: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        locale: payload,
      };
    },
    setTablePageSize: (state, { payload }: PayloadAction<number>) => {
      if (isNumber(payload)) {
        return {
          ...state,
          table: {
            ...state.table,
            page: 1,
            pageSize: payload,
          },
        };
      }

      return state;
    },
    setProductsLastViewed: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        products: {
          ...state.products,
          lastViewed: payload,
        },
      };
    },
    setOption: (state, { payload }: PayloadAction<{ key: keyof InitialState['options']; payload: Option[] }>) => {
      return {
        ...state,
        options: {
          ...state.options,
          [payload.key]: payload,
        },
      };
    },
    setSyncedRecipientList: (state, { payload }: PayloadAction<SyncedRecipientOption[] | null>) => {
      return {
        ...state,
        options: {
          ...state.options,
          syncedRecipientList: payload,
        },
      };
    },
    setCandidatesWithKey: (
      state,
      { payload }: PayloadAction<{ key: keyof InitialState['candidates']; payload: unknown }>
    ) => {
      return {
        ...state,
        candidates: {
          ...state.candidates,
          [payload.key]: payload.payload,
        },
      };
    },
    setFiltersWithKey: (
      state,
      { payload }: PayloadAction<{ key: keyof InitialState['filters']; payload: unknown }>
    ) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.key]: payload.payload,
        },
      };
    },
    setPositionsEdited: (state, { payload }: PayloadAction<unknown[]>) => {
      return {
        ...state,
        positionsEdited: payload,
      };
    },
    setIsAddingBlock: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        careerPage: {
          ...state?.careerPage,
          addingBlock: payload,
        },
      };
    },
    setUpperBlock: (state, { payload }: PayloadAction<InitialState['careerPage']['upperBlock']>) => {
      return {
        ...state,
        careerPage: {
          ...state?.careerPage,
          upperBlock: payload,
        },
      };
    },
    setCandidatesSorts: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        sorts: {
          ...state.sorts,
          candidates: payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // recruiters options
    // @ts-ignore
    builder.addCase(getClientsConfig.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          // @ts-ignore
          recruiters: payload.recruiters,
        },
        loading: false,
      };
    });

    // configOptions
    // @ts-ignore
    builder.addCase(setConfigOptions.fulfilled, (state, { payload }: PayloadAction<Options>) => {
      return {
        ...state,
        options: {
          ...state.options,
          clientTypes: createArrayFromConfigOptions(payload?.clientTypes),
          companyIndustries: createArrayFromConfigOptions(payload?.companyIndustries),
          jobFunctions: payload?.jobFunctions,
        },
      };
    });

    // setPositionsOptions
    builder.addCase(setPositionsOptions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          positions: payload as Option[],
        },
      };
    });

    // setTagsOptions
    builder.addCase(setTagsOptions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          tags: payload as Option[],
        },
      };
    });

    // setRecruitersOptions
    builder.addCase(setRecruitersOptions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          recruiters: payload as Option[],
        },
      };
    });

    // setDepartmentsOptions
    builder.addCase(setDepartmentsOptions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          departments: payload as Option[],
        },
      };
    });

    // getDepartmentsOptions
    builder.addCase(getDepartmentsOptions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          departments: payload as Option[],
        },
      };
    });

    // setClientsOptions
    builder.addCase(setClientsOptions.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          clients: payload as Option[],
        },
      };
    });

    // set pipeline templates in options
    builder.addCase(setPipelineTemplates.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          templates: (payload as Template[]).map((item) => ({ ...item, value: item.id })),
        },
      };
    });

    // set pipeline steps
    builder.addCase(setPipelineSteps.fulfilled, (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        options: {
          ...state.options,
          steps: payload,
        },
      };
    });

    // set pipeline steps
    builder.addCase(setRefappQuestionnaires.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          refappQuestionnaires: payload as ExternalConfigOption[],
        },
      };
    });

    // set pipeline steps
    builder.addCase(setAlvaLabsProfiles.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          alvaLabsProfiles: payload as ExternalConfigOption[],
        },
      };
    });

    // set pipeline steps
    builder.addCase(setHubertInterviewTemplates.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          hubertInterviewTemplates: payload as ExternalConfigOption[],
        },
      };
    });

    // set pipeline steps
    builder.addCase(setAmsGroups.fulfilled, (state, { payload }: PayloadAction<AmsGroupsPayload>) => {
      return {
        ...state,
        options: {
          ...state.options,
          amsGroups: payload.occupationTree,
          municipalities: payload.municipalities,
        },
      };
    });

    // set pipeline steps
    builder.addCase(getClientsExternalConfig.fulfilled, (state, { payload }) => {
      return {
        ...state,
        options: {
          ...state.options,
          refappQuestionnaires: payload.refappOptionsList,
          sriRecipients: payload.sriRecipientsList,
          sriOrderers: payload.sriOrderersList,
          alvaLabsProfiles: payload.alvaLabsTestProfiles,
          hubertInterviewTemplates: payload.hubertInterviewTemplates,
        },
      };
    });

    // set calendar details
    builder.addCase(setCalendarDetails.fulfilled, (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        calendar: payload,
      };
    });

    // set candidates active
    builder
      .addCase(getCandidateDetails.pending, (state) => {
        return {
          ...state,
          candidates: {
            ...state.candidates,
            active: {
              ...state.candidates.active,
              loading: true,
            },
          },
        };
      })
      .addCase(getCandidateDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          candidates: {
            ...state.candidates,
            active: {
              ...payload,
              loading: false,
            },
          },
        };
      });

    builder.addCase(setCandidatesFilter.fulfilled, (state, { payload }: PayloadAction<any>) => {
      return {
        ...state,
        position: payload,
        filters: {
          ...state.filters,
          candidates: {
            ...state.filters.candidates,
            positionId: payload.id,
            pipelineStepId: payload.pipelineStepId,
            status: 'Active',
            rejected: false,
          },
        },
      };
    });

    // @ts-ignore
    builder
      .addCase(getPageSettingsThunk.pending, (state) => {
        return {
          ...state,
          careerPage: {
            ...state?.careerPage,
            pageSettings: {
              loading: true,
            },
          },
        };
      })
      .addCase(getPageSettingsThunk.fulfilled, (state, { payload }) => {
        return {
          ...state,
          careerPage: {
            ...state.careerPage,
            pageSettings: {
              ...state.careerPage.pageSettings,
              ...payload,
              loading: false,
            },
          },
        };
      });

    // getAllSettings
    builder
      .addCase(getAllSettings.pending, (state) => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllSettings.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(getAllSettings.rejected, (state, { payload }) => {
        return {
          ...state,
          loading: false,
          error: get(payload, 'error_description'),
        };
      });
  },
});

export const {
  resetAllSettings,
  setActivePipelineStepId,
  setActivePosition,
  setCandidatesTab,
  toggleMenuState,
  setLocale,
  setTablePageSize,
  setProductsLastViewed,
  setOption,
  setSyncedRecipientList,
  setCandidatesWithKey,
  setFiltersWithKey,
  setPositionsEdited,
  setIsAddingBlock,
  setUpperBlock,
  setCandidatesSorts,
} = slice.actions;
// @ts-ignore
export default slice.reducer;
