import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import {
  addOrUpdateIntegration,
  toggleIntegrationsActive,
} from '../../../../store/api/client-integrations.api';
import Divider from '../../../../components/@v2/Divider/Divider';
import { getClient } from '../../../../store/thunks/client.thunk';
import { useSelector } from '../../../../store';
import { selectClient } from '../../../../store/selectors/client.selector';
import { selectUser } from '../../../../store/selectors/user.selector';
import { useToasts } from "react-toast-notifications";

export const AmsFormSchema = (t) => {
  return Yup.object().shape({
    senderId: Yup.string().nullable().required(t('form-validator.required')),
    hiringOrganizationId: Yup.string()
      .nullable()
      .required(t('form-validator.required')),
  });
};

const ID = 'ams';

const AmsForm = ({
  initialValues = {
    senderId: '',
    hiringOrganizationId: '',
    isActive: false
  },
}) => {
  const { id: clientId } = useSelector(selectClient);
  const { superAdmin } = useSelector(selectUser);
  const { t } = useTranslation();
  const history = useHistory();
  const isEditing = !!initialValues?.id;
  const [error, setError] = useState('');
  const { addToast } = useToasts();

  const validateHiringOrganizationIdInput=(value)=>{

    if (value.length !== 11) {
      return false;
    }
    const regex = /^\d{6}-\d{4}$/;
    return regex.test(value);

  }

  const onSubmit = async (values) => {

    if (!validateHiringOrganizationIdInput(values?.hiringOrganizationId)) {

      addToast(`Invalid length or format for ${t('settings.integrations.hiring-organization-id')} .`, { appearance: "error", autoDismiss: true });

    }else{
      const configModel = {
        isActive: values?.isActive,
        senderId: values?.senderId,
        senderEmail: values?.senderEmail || '',
        hiringOrganizationId: "46-"+ values?.hiringOrganizationId,
      };
  
      const finalData = {
        configModel,
        type: values?.type?.toLowerCase() || ID,
      };
  
      if (values?.id) {
        finalData.id = values?.id;
      }
  
      if (values?.type) {
        finalData.type = values?.type;
      }
  
      try {
        if (values?.id && initialValues?.isActive !== values?.isActive) {
          await toggleIntegrationsActive(
            ID,
            values?.isActive ? 'activate' : 'deactivate'
          );
        }
        await addOrUpdateIntegration(finalData);
        await getClient({
          superAdmin,
          id: clientId,
        });
  
        if (isEditing) {
          history.push('/settings/integrations');
  
          return;
        }
  
        history.push(`/settings/integrations/edit/${ID}`);
      } catch (error) {
        // TODO: display actual translated error message once feature from BE for setting error keys is finished
        setError(error ? t('error-message.something-went-wrong') : '');
        throw error;
      }

    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={AmsFormSchema(t)}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            {!!error && (
              <Alert color="danger" className="mb-4">
                {error}
              </Alert>
            )}
            {isEditing && (
              <>
                <div className="flex items-center justify-between mb-5">
                  <img
                    className="logo w-[250px]"
                    src={`${global?.appConfig?.public_url}/img/integrations/${ID}.png`}
                    alt="logo"
                  />
                  <Field
                    name="isActive"
                    label={t('general.active')}
                    type="switch"
                    className="!w-auto !mb-0"
                  />
                </div>
                <Divider>
                  {t('settings.integrations.acceptance-threshold')}
                </Divider>
              </>
            )}
            <Field
              name="senderId"
              label={t('settings.integrations.sender-id')}
              required
            />
           
            <Field
              name="hiringOrganizationId"
              label={t('settings.integrations.hiring-organization-id')}
              inputGroupClassName='input-group'
              required
            />
            <Button
              type="submit"
              color="primary"
              disabled={isSubmitting}
              loading={isSubmitting}
              data-testid="settings-integration-submit-button"
            >
              {t('general.submit')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export default AmsForm;
