import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { truncate, get, omit, isEmpty, sortBy, find } from 'lodash';
import { useLocation } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import uuidv4 from 'uuid/v4';
import { useAPI } from '../../../context/api';
import SelectV2 from '../../@v2/Select/Select';
import { SalaryType } from '../../../constants/Position';
import { BoostAutoProduct, AmsProductFields } from '../../../constants/Product';
import Product from '../../Product';
import CustomButton from '../../Button';
import { addItem } from '../../../store/slices/cart.slice';
import SpecialOffer from '../../products/SpecialOffer';
import ProductLoader from '../../ProductLoader';
import { setProductsLastViewed } from '../../../store/slices/settings.slice';
import { log } from '../../../helpers/utils';
import { useSelector as useSelectorToolkit, dispatch as dispatchToolkit } from '../../../store';
import { selectClientConfig } from '../../../store/selectors/client.selector';
import { selectAmsGroups, selectMunicipalities } from '../../../store/selectors/settings.selector';
import events from '../../../helpers/events';

const Promote = ({ state, setState, products, isProductsFetching, handleSubmitForm }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const activeClient = useSelectorToolkit(selectClientConfig);
  const [locationAPI] = useAPI('location');
  const [toggledAmsProduct, setToggledAmsProduct] = useState([]);
  const [isValidating, setIsValidating] = useState(false);
  const cart = useSelectorToolkit(({ cart }) => cart);
  const amsGroups = useSelectorToolkit(selectAmsGroups);
  const municipalities = useSelectorToolkit(selectMunicipalities);

  const AmsProductType = 100;

  const amsFieldValues = AmsProductFields(t).map(({ value }) => value);

  const salaryOptions = useMemo(
    () =>
      SalaryType.map((item) => ({
        ...item,
        label: t(item.label),
      })),
    [SalaryType]
  );

  const hasExistingAmsProduct = useMemo(
    () => state && state.orderItems && state?.orderItems.find(({ customFields }) => customFields?.occupationNameId),
    [state]
  );

  const amsCategoryOptions = useMemo(
    () =>
      (amsGroups || []).map((item) => ({
        value: item.name,
        label: item.name,
        groups: item.groups,
      })),
    [amsGroups]
  );

  const municipalityOptions = useMemo(
    () =>
      (municipalities || []).map((item) => ({
        value: item.municipalityID,
        label: item.term,
      })),
    [municipalities]
  );

  const isAutoBoostEnabled = useMemo(
    () =>
      get(activeClient, 'integrationSettings.BoostAutoToggle') && state.hidden === false && state.spontaneous === false,
    [state.hidden, state.spontaneous]
  );

  const pushToCart = (item) => {
    dispatchToolkit(
      addItem({
        ...item,
        positionId: state?.positionId || null,
        positionName: state?.name || '',
        locationName: state?.locationName || '',
        recruiterId: state?.recruiterId || null,
        key: uuidv4(),
      })
    );

    events.$emit('cart--toggle-popover', true);
  };

  const toggledAmsIDs = useMemo(() => (toggledAmsProduct || []).map(({ id }) => id), [toggledAmsProduct]);

  const isInvalidAmsProduct = useCallback(
    (key, id) => {
      if (!key || !id) return false;
      const amsProduct = toggledAmsProduct.find((item) => item.id === id);
      const foundField = amsProduct?.customFields[key];

      return isValidating === true && (!foundField || foundField?.value === '');
    },
    [toggledAmsProduct, isValidating]
  );

  const [locationLoading, setLocationLoading] = useState(false);

  useEffect(() => {
    dispatchToolkit(setProductsLastViewed(`${pathname}?currentView=4`));
  }, []);

  const getLocationName = async (id) => {
    setLocationLoading(true);
    try {
      const item = await locationAPI.read(id);
      setState('locationName', item.name);
    } catch (error) {
      log(error);
    } finally {
      setLocationLoading(false);
    }
  };

  const isSubmittedAsDraft = useRef(false);

  useEffect(() => {
    if (state.positionId === 0 && !isSubmittedAsDraft.current) {
      handleSubmitForm({ draft: true });
      isSubmittedAsDraft.current = true;
    }
    if (state.locationId && !state.locationName) {
      getLocationName(state.locationId);
    }
  }, [state, isSubmittedAsDraft]);

  const updateProductCustomField = useCallback(
    (key, value, selectedProduct) => {
      setToggledAmsProduct(
        toggledAmsProduct.length
          ? [...toggledAmsProduct].map((item) => {
              if (item?.id === selectedProduct.id) {
                const getAmsValue = (keys) =>
                  AmsProductFields(t)
                    .filter((item) => keys.includes(item.value))
                    .map(({ value }) => value);
                const mappedItem = {
                  ...item,
                  customFields: {
                    ...item.customFields,
                    type: AmsProductType,
                    [key]: value,
                    ...(key === 'municipalityId' && { municipalityId: value }),
                  },
                };
                const fieldsForRemoval =
                  key === 'amsOccupationOptions'
                    ? getAmsValue(['occupationNameId'])
                    : getAmsValue(['amsOccupationOptions', 'occupationNameId']);

                return key === 'occupationNameId' || key === 'salaryType' || key === 'municipalityId'
                  ? mappedItem
                  : {
                      ...mappedItem,
                      customFields: omit(mappedItem.customFields, fieldsForRemoval),
                    };
              }
              return item;
            })
          : [selectedProduct]
      );
    },
    [toggledAmsProduct]
  );

  const getAmsGroupOptions = useCallback(
    (productId) => {
      if (!toggledAmsProduct || !productId) return [];
      const amsProduct = toggledAmsProduct.find(({ id }) => id === productId);
      return amsProduct !== undefined
        ? amsProduct?.customFields?.amsGroupOptions?.groups?.map((group) => ({
            label: group.name,
            value: group.name,
            occupations: group.occupations,
          }))
        : [];
    },
    [toggledAmsProduct]
  );

  const getAmsOccupationOptions = useCallback(
    (productId) => {
      if (!toggledAmsProduct || !productId) return [];
      const amsProduct = toggledAmsProduct.find(({ id }) => id === productId);
      return amsProduct !== undefined
        ? amsProduct?.customFields?.amsOccupationOptions?.occupations?.map((group) => ({
            label: group.name,
            value: group.id,
          }))
        : [];
    },
    [toggledAmsProduct]
  );

  const renderProduct = (product, index) => {
    const updatedProduct = {
      ...product,
      customFields: {},
    };
    const displayBtnText = () => {
      const defaultText = product.isBoostAuto ? t('general.already-included') : t('general.add-to-cart');

      return product.ctaText || defaultText;
    };

    const setAMSProductFields = (key, value) => updateProductCustomField(key, value, updatedProduct);

    const defaultAmsFieldValue = (key) => {
      const foundField = toggledAmsProduct.find(({ id }) => id === product.id);
      if (!foundField) return [];
      return foundField?.customFields[key] || [];
    };

    // TODO: Needs to be refactored into a formik form
    return (
      <Product
        key={product.id}
        title={toggledAmsIDs ? t('general.publish-on-ams') : product.title}
        {...product}
        isPromoted
        toggledAmsProduct={toggledAmsIDs}
        className="product-promoted overflow-hidden"
        data-testid={`product-item-${index}`}
      >
        {product.specialOffer && !toggledAmsIDs.includes(product.id) && (
          <SpecialOffer>{truncate(product.specialOffer, { length: 10 })}</SpecialOffer>
        )}
        {toggledAmsIDs.includes(product.id) && (
          <div className="d-flex flex-column flex-grow-1">
            <p className="product-title mb-4">{t('general.publish-on-ams')}</p>
            <p className="text-base">{t('general.occupation')}</p>
            <div className="mb-4">
              {/* TODO: Needs to be refactored inside a formik form */}
              <SelectV2
                label={AmsProductFields(t)[0].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('amsGroupOptions', selected);
                }}
                defaultOptions={amsCategoryOptions}
                className="mb-2"
                id="amsGroupOptions"
                resource="amsGroupOptions"
                invalid={isInvalidAmsProduct('amsGroupOptions', product.id)}
                disabled={!amsCategoryOptions.length}
              />
              <SelectV2
                label={AmsProductFields(t)[1].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('amsOccupationOptions', selected);
                }}
                defaultOptions={getAmsGroupOptions(product.id)}
                className="mb-2"
                id="amsOccupationOptions"
                resource="amsOccupationOptions"
                value={defaultAmsFieldValue('amsOccupationOptions')}
                invalid={isInvalidAmsProduct('amsOccupationOptions', product.id)}
                disabled={!(toggledAmsProduct || []).find(({ id }) => id === product.id)?.customFields?.amsGroupOptions}
              />
              <SelectV2
                label={AmsProductFields(t)[2].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('occupationNameId', selected?.value);
                }}
                defaultOptions={getAmsOccupationOptions(product.id)}
                className="mb-2"
                id="occupationNameId"
                resource="occupationNameId"
                value={defaultAmsFieldValue('occupationNameId')}
                invalid={isInvalidAmsProduct('occupationNameId', product.id)}
                disabled={
                  !(toggledAmsProduct || []).find(({ id }) => id === product.id)?.customFields?.amsOccupationOptions
                }
              />
              <SelectV2
                label={AmsProductFields(t)[3].label}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('municipalityId', selected?.value);
                }}
                defaultOptions={municipalityOptions}
                className="mb-2"
                id="municipalityId"
                resource="municipalityId"
                value={defaultAmsFieldValue('municipalityId')}
                disabled={
                  !(toggledAmsProduct || []).find(({ id }) => id === product.id)?.customFields?.amsOccupationOptions
                }
              />
              <p className="text-base text-color-secondary mt-4">{t('edit-position.salary-type')}</p>
              <SelectV2
                label={`${t('general.select')} ${t('edit-position.salary')}`}
                searchable={false}
                onSelect={([selected]) => {
                  setAMSProductFields('salaryType', selected?.value);
                }}
                defaultOptions={salaryOptions}
                className="mb-2"
                id="amsSalaryType"
                resource="amsSalaryType"
                value={defaultAmsFieldValue('salaryType')}
                invalid={isInvalidAmsProduct('salaryType', product.id)}
              />
            </div>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-center">
          <CustomButton
            color="success"
            className="btn-rounded px-4"
            outline
            size="xs"
            onClick={() => {
              setIsValidating(true);
              if (updatedProduct.adTypes.includes(AmsProductType) && !toggledAmsIDs.includes(updatedProduct.id)) {
                const newAmsProduct = { ...updatedProduct, isAms: true };
                setToggledAmsProduct([...toggledAmsProduct, newAmsProduct]);
                setIsValidating(false);
                return;
              }

              if (updatedProduct.externalLink && !updatedProduct.adTypes.includes(AmsProductType)) {
                let externalUrl = updatedProduct.externalLink;
                if (!externalUrl.match(/^https?:\/\//i)) {
                  externalUrl = `http://${externalUrl}`;
                }
                const win = window.open(externalUrl, '_blank');
                if (win != null) win.focus();
              } else {
                const foundAmsProduct =
                  toggledAmsProduct.length && toggledAmsProduct.find(({ id }) => id === product.id);

                if (
                  foundAmsProduct &&
                  (isEmpty(foundAmsProduct?.customFields) ||
                    amsFieldValues.filter((item) => isInvalidAmsProduct(item, product.id)).length >= 1)
                ) {
                  NotificationManager.warning(t('error-message.field-is-required'));
                  return;
                }
                // already added to cart
                if (
                  cart.find(
                    (cartItem) => cartItem.positionId === state?.positionId && cartItem.id === updatedProduct.id
                  )
                ) {
                  NotificationManager.warning(t('error-message.the-product-is-already-added-for-this-position'));
                  return;
                }
                // ams product
                const isAmsProductType = product.adTypes?.includes(AmsProductType);
                if (
                  (isAmsProductType &&
                    !!cart.find(
                      (cartItem) =>
                        cartItem.positionId === state?.positionId && cartItem.adTypes?.includes(AmsProductType)
                    )) ||
                  (isAmsProductType && hasExistingAmsProduct)
                ) {
                  NotificationManager.warning(t('error-message.the-position-already-has-an-ams-product'));
                  return;
                }

                const selectedProduct =
                  toggledAmsProduct.length && updatedProduct.adTypes.includes(AmsProductType)
                    ? find(toggledAmsProduct, (item) => item.id === updatedProduct.id)
                    : { ...updatedProduct };

                pushToCart(selectedProduct);
              }
            }}
            disabled={product.isBoostAuto || locationLoading}
            data-testid={`product-item-${index}-button`}
          >
            {product.adTypes.includes(AmsProductType) && !toggledAmsIDs.includes(product.id)
              ? t('general.configure')
              : displayBtnText()}
          </CustomButton>
        </div>
      </Product>
    );
  };

  return (
    <>
      {get(activeClient, 'integrationSettings.showRekryShop') >= 1 && (
        <>
          <h3
            className="line-behind-title"
            style={{
              marginTop: state.useAlternativeApplyForm ? 10 : null,
            }}
          >
            {t('edit-position.promote-position')}
          </h3>
          {(isProductsFetching || state.positionId === 0) && <ProductLoader />}
          {!isProductsFetching && state.positionId !== 0 && (
            <div className="promote-position anim-table-delayed">
              <Row className="flex-wrap">
                {!products.length && <p style={{ padding: '5px 15px' }}>{t('edit-position.no-products-available')}</p>}
                {!!isAutoBoostEnabled &&
                  renderProduct({
                    ...BoostAutoProduct,
                    title: t('general.boost-auto'),
                    description: t('general.boost-auto-description'),
                  })}
                {sortBy(
                  (products || []).map((product, index) => renderProduct(product, index)),
                  'sortOrder'
                )}
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Promote;
