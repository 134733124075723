import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { getPositionLookupList as getPositionLookupListRequest } from '../api/positions.api';
import { browseDepartments as browseDepartmentsRequest } from '../api/departments.api';
import { getAllTags as getAllTagsRequest } from '../api/candidates.api';
import { getAllPipelineTemplates, getAllPipelineSteps } from '../api/pipelines.api';
import { getPageSettings, getAdminClients } from '../api/client.api';
import { getConfig as getConfigRequest } from '../api/config.api';
import {
  getAlvaLabsProfiles,
  getRefappQuestioneers,
  getAmsGroups,
  getSriRecipients,
  getSriOrderers,
  getHubertInterviewTemplates,
} from '../api/client-integrations.api';
import { log } from '../../helpers/utils';
import { toggleSplash } from '../../helpers/toggle-splash';
import { getClientsExternalConfig } from './client.thunk';
import { getClientAccessDetails } from '../api/user.api';

type Position = {
  id: number;
  value?: number;
  label?: string;
  name: string;
};

type Recruiter = {
  id: number;
  value?: number;
  label?: string;
  name: string;
};

export const setPositionsOptions = createAsyncThunk<Position[], any, { rejectValue: Error }>(
  'settings/setPositionsOptions',
  async (positions, { rejectWithValue }) => {
    try {
      return (positions || []).map((item) => {
        item.value = item.id;
        item.label = item.name;

        return item;
      });
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getPositionOptions = createAsyncThunk<Position[], void, { rejectValue: Error }>(
  'settings/getPositionOptions',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const positions = await getPositionLookupListRequest();
      return dispatch(setPositionsOptions(positions)).unwrap();
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const setRecruitersOptions = createAsyncThunk<Recruiter[], Recruiter[], { rejectValue: Error }>(
  'settings/setRecruitersOptions',
  async (recruiters, { rejectWithValue }) => {
    try {
      return (recruiters || []).map((item) => {
        item.value = item.id;
        item.label = item.name;

        return item;
      });
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export type DepartmentOption = { value: string; label: string; [key: string]: any };
const createOptions = <T extends DepartmentOption>(arr: T[] = [], value: keyof T, label: keyof T) => {
  return arr.map((item) => {
    item.value = item[value];
    item.label = item[label];
    return item;
  });
};

export const setDepartmentsOptions = createAsyncThunk(
  'settings/setDepartmentsOptions',
  async (departments: DepartmentOption[]): Promise<unknown[]> => {
    return createOptions(departments, 'departmentId', 'name');
  }
);

export const getDepartmentsOptions = createAsyncThunk(
  'settings/getDepartmentsOptions',
  async (): Promise<unknown[]> => {
    const response: any = await browseDepartmentsRequest({
      filter: {
        archived: false,
      },
    });

    return createOptions(response.items, 'departmentId', 'name');
  }
);

export const setPipelineSteps = createAsyncThunk(
  'settings/setPipelineSteps',
  async (): Promise<AxiosResponse<any[]> | undefined[]> => {
    const pipelineSteps: any = await getAllPipelineSteps();
    return pipelineSteps || [];
  }
);

export const setRefappQuestionnaires = createAsyncThunk(
  'settings/setRefappQuestionnaires',
  async (): Promise<unknown[]> => {
    const response: any = await getRefappQuestioneers();

    return (response.questionerList || []).map((item) => ({
      ...item,
      value: item.id,
    }));
  }
);

export const setAlvaLabsProfiles = createAsyncThunk('settings/setAlvaLabsProfiles', async (): Promise<unknown[]> => {
  const response: any = await getAlvaLabsProfiles();
  return (response.testProfileList || []).map((item) => ({
    ...item,
    value: item.id,
  }));
});

export const setHubertInterviewTemplates = createAsyncThunk(
  'settings/setHubertInterviewTemplates',
  async (): Promise<unknown[]> => {
    const response: any = await getHubertInterviewTemplates();
    return (response.hubertInterviewTemplateList || []).map((item) => ({
      ...item,
      value: item.id,
    }));
  }
);

export const setAmsGroups = createAsyncThunk('settings/setAmsGroups', async (): Promise<unknown> => {
  const { municipalities, occupationTree } = await getAmsGroups();
  return { municipalities, occupationTree };
});

export const setSriRecipients = createAsyncThunk('settings/setSriRecipients', async (): Promise<unknown[]> => {
  const { questionerList }: any = await getSriRecipients();
  return (questionerList || []).map((item) => ({
    ...item,
    value: item.id,
  }));
});

export const setSriOrderers = createAsyncThunk('settings/setSriOrderers', async (): Promise<unknown[]> => {
  const { questionerList }: any = await getSriOrderers();
  return (questionerList || []).map((item) => ({
    ...item,
    value: item.id,
  }));
});

export const setPipelineTemplates = createAsyncThunk('settings/setPipelineTemplates', async (): Promise<unknown[]> => {
  const pipelineTemplates: any = await getAllPipelineTemplates();
  return (pipelineTemplates || []).map((item) => {
    item.value = item.pipelineTemplateId;
    item.label = item.name;
    return item;
  });
});

export const setCandidatesFilter = createAsyncThunk(
  'settings/setCandidatesFilter',
  async (filter: string): Promise<string> => {
    return filter;
  }
);

interface SetClientsOptionsPayload {
  clients: { value: string; label: string; name: string; clientId: string }[];
}

export const setClientsOptions = createAsyncThunk(
  'settings/setClientsOptions',
  async ({ clients }: SetClientsOptionsPayload): Promise<unknown[]> => {
    return clients.map((item) => {
      item.value = item?.clientId;
      item.label = item?.name;

      return item;
    });
  }
);

export const getClientsOptions = createAsyncThunk('settings/setClientsOptions', async (): Promise<unknown[]> => {
  const { items }: any = await getAdminClients({
    page: {
      size: 10,
      number: 1,
    },
  });
  return items.map((item) => {
    item.value = item?.id;
    item.label = item?.name;

    return item;
  });
});

export const setActivePositionPipeline = createAsyncThunk(
  'settings/setActivePositionPipeline',
  async (pipeline: any): Promise<any> => {
    return pipeline;
  }
);

interface SetTagsOptionsPayload {
  tags: string[];
}

export const setTagsOptions = createAsyncThunk(
  'settings/setTagsOptions',
  async ({ tags }: SetTagsOptionsPayload): Promise<unknown[]> => {
    return tags.map((tag) => ({
      label: tag,
      value: tag,
    }));
  }
);

export const getAllTags = createAsyncThunk('settings/getAllTags', async (_, { dispatch }): Promise<void> => {
  const tags: any = await getAllTagsRequest();

  await dispatch(setTagsOptions({ tags }));
});

export const setConfigOptions = createAsyncThunk(
  'settings/setConfigOptions',
  async (options: Record<string, unknown>): Promise<Record<string, unknown>> => {
    return options;
  }
);

export const getConfig = createAsyncThunk('settings/getConfig', async (_, { dispatch }): Promise<void> => {
  const response: any = await getConfigRequest();

  await dispatch(setConfigOptions(response));

  return response;
});

// const getPositionSettings = async (dispatch: any): Promise<void> => {
//   try {
//     const positions = (await getPositionListRequest({
//       filter: {
//         status: 0,
//         name: null,
//         recruiterUserId: null,
//         departmentId: null,
//         locationId: null,
//       },
//       page: {
//         size: 20,
//         number: 1,
//       },
//       sort: {
//         by: SortBy.ApplicationDeadline,
//         direction: 1,
//       },
//     })) as any;

//     await dispatch(setPositionsOptions(positions?.items));

//     if (positions?.items[0]) {
//       const getFirstPipelineStep = (pipelines: any[]): any | null => {
//         if (pipelines && pipelines.length) {
//           const findItemWithCandidates = pipelines
//             .sort((a, b) => a.sortOrder - b.sortOrder)
//             .find((item) => item.candidates >= 1);
//           if (findItemWithCandidates) {
//             return findItemWithCandidates;
//           }
//         }

//         return null;
//       };

//       const pipelinesResponse = (await getPipelineStepsRequest(positions?.items[0].id, {
//         filters: 'status==Active',
//       })) as any;

//       const pipelines = pipelinesResponse?.items || [];

//       if (pipelines) {
//         await dispatch(
//           setActivePosition({
//             ...positions?.items[0],
//             pipelines,
//             pipelineStepId: get(getFirstPipelineStep(pipelines), 'id') || null,
//           })
//         );

//         await dispatch(
//           setCandidatesFilter({
//             ...positions[0]?.items[0],
//             pipelineStepId: get(getFirstPipelineStep(pipelines), 'id') || null,
//           })
//         );

//         await dispatch(
//           setActivePositionPipeline({
//             ...positions[0]?.items[0],
//             pipelines,
//             activePipelineStepId: get(getFirstPipelineStep(pipelines), 'id') || null,
//           })
//         );
//       }
//     }
//   } catch (error: any) {
//     log('POSITIONS REQUEST FAILED:', error);
//   }
// };

// const getRecruitersSettings = async (dispatch: any): Promise<void> => {
//   try {
//     const items: any = await getRecruitersRequest();

//     await dispatch(setRecruitersOptions(items || []));
//   } catch (error: any) {
//     log('RECRUITERS REQUEST FAILED:', error);
//   }
// };

// const getDeparmentsSettings = async (dispatch: any): Promise<void> => {
//   try {
//     const { items }: any = await browseDepartmentsRequest({
//       pageSize: 10,
//       filters: 'deleted==false',
//     });

//     await dispatch(setDepartmentsOptions(items || []));
//   } catch (error: any) {
//     log('DEPARTMENTS REQUEST FAILED:', error);
//   }
// };

const getClientsSettings = async (dispatch: any): Promise<void> => {
  try {
    const { clients }: any = await getClientAccessDetails();

    await dispatch(setClientsOptions({ clients }));
  } catch (error: any) {
    log('CLIENTS REQUEST FAILED:', error);
  }
};

export const setCalendarDetails = createAsyncThunk('settings/setCalendarDetails', async (response: any) => {
  return response;
});

// const getCalendarSettings = async (dispatch: any): Promise<void> => {
//   try {
//     const response = await getCalendarSettingsRequest();

//     await dispatch(setCalendarDetails(response));
//   } catch (error: any) {
//     log('CALENDAR REQUEST FAILED:', error);
//   }
// };

export const setPageSettings = createAsyncThunk('settings/setPageSettings', async (response: any) => {
  return response;
});

export const getPageSettingsThunk = createAsyncThunk('settings/getPageSettingsThunk', async () => {
  try {
    const response = await getPageSettings();
    return response;
  } catch (error: any) {
    log('PAGE SETTINGS REQUEST FAILED:', error);
    return {};
  }
});

export const getAllSettings = createAsyncThunk(
  'settings/getAllSettings',
  async (disableLoaders: boolean | undefined, { dispatch }) => {
    if (!disableLoaders) {
      toggleSplash(true);
    }

    dispatch(getClientsExternalConfig());
    dispatch(getConfig());
    getClientsSettings(dispatch);
    dispatch(getAllTags());

    if (!disableLoaders) {
      toggleSplash(false);
    }
  }
);
