import classNames from 'classnames';
import { Formik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';
import * as Yup from 'yup';
import { omit } from 'lodash';
import Field from '../../../../components/@v2/Form/Field';
import Button from '../../../../components/Button';
import Layout from '../../../../components/layouts/default';
import {
  DefaultClientIntegration,
  RefappAutomationMailOptions,
  RefappRefereesOptions,
} from '../../../../constants/ClientIntegration';
import { pipelineStepTabs } from '../../../../constants/Pipeline';
import ApiRequestProvider, { useApiRequestContext } from '../../../../context/@v2/ApiRequestContext';
import { capitalizeFirstLetter, log } from '../../../../helpers/utils';
import useAssessment from '../../../../hooks/useAssessment';
import { useSelector } from '../../../../store';
import { createOrUpdatePipelineStep, getPipelineStep } from '../../../../store/api/pipelines.api';
import {
  selectAlvaLabsProfiles,
  selectHubertInterviewTemplates,
  selectRefappQuestionaires,
  selectSriRecipients,
  selectSriOrderers,
} from '../../../../store/selectors/settings.selector';
import '../../../../assets/sass/setting-form.sass';
import { getIntegration } from '../../../../store/api/client-integrations.api';

export const PipelineStepFormSchema = (t) => {
  return Yup.object().shape({
    name: Yup.string().nullable().required(t('form-validator.required')),
  });
};

const PipelineStepForm = () => {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const { loading, data } = useApiRequestContext();
  const {
    hasAssessio,
    hasRefapp,
    hasAlvaLabs,
    hasHubert,
    hasSri,
    hasAssessments,
    assessmentsIds,
    options: ASSESSIO_OPTIONS,
  } = useAssessment();

  const ALVA_LABS_PROFILE_OPTIONS = useSelector(selectAlvaLabsProfiles);
  const REFAPP_QUESTIONNAIRES_OPTIONS = useSelector(selectRefappQuestionaires);
  const HUBERT_INTERVIEW_TEMPLATE_OPTIONS = useSelector(selectHubertInterviewTemplates);
  const SRI_RECIPIENTS_OPTIONS = useSelector(selectSriRecipients);
  const SRI_ORDERERS_OPTIONS = useSelector(selectSriOrderers);
  const history = useHistory();

  const [assessioConfig, setAssessionConfig] = useState([]);

  const assessioOptions = useMemo(() => {
    return ASSESSIO_OPTIONS.filter((test) => {
      const isActive = !!assessioConfig.find((config) => config.assessmentType === test.id)?.isActive;

      return isActive;
    });
  }, [ASSESSIO_OPTIONS, assessioConfig]);

  const INTEGRATION_OPTIONS = DefaultClientIntegration.filter(
    ({ id }) =>
      (assessmentsIds.refapp === id && hasRefapp) ||
      (assessmentsIds.assessio === id && hasAssessio) ||
      (assessmentsIds.alvaLabs === id && hasAlvaLabs) ||
      (assessmentsIds.hubert === id && hasHubert) ||
      (assessmentsIds.sri === id && hasSri)
  ).map((item) => ({
    ...item,
    key: item.id,
    value: item.id,
  }));

  const getAssessioConfig = async () => {
    try {
      const response = await getIntegration('assessio');

      setAssessionConfig(response.configModel.settings);
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    if (!hasAssessio) {
      return;
    }

    getAssessioConfig();
  }, [hasAssessio]);

  const onSubmit = async (values) => {
    const getAssessmentValues = (values) => {
      if (
        !values?.integrationType ||
        (values?.integrationType?.id === assessmentsIds.assessio && !values?.assessmentType)
      ) {
        return null;
      }

      return {
        type:
          values?.integrationType?.id === assessmentsIds.assessio
            ? values?.assessmentType?.id
            : values?.integrationType?.id,
        refappQuestionerId:
          values?.integrationType?.id === assessmentsIds.refapp ? values?.refappQuestionerId?.id : null,
        alvaLabsTestProfileId:
          values?.integrationType?.id === assessmentsIds.alvaLabs ? values?.alvaLabsTestProfileId?.id : null,
        hubertInterviewTemplateId: 
          values?.integrationType?.id === assessmentsIds.hubert ? values?.hubertInterviewTemplateId?.id : null,
        hubertThreshold: 
          values?.integrationType?.id === assessmentsIds.hubert ? values?.hubertThreshold : null,
        refereeMailReferenceMethodType:
          values?.integrationType?.id === assessmentsIds.refapp
            ? parseInt(values?.refereeMailReferenceMethodType?.value || values?.refereeMailReferenceMethodType, 10)
            : null,
        referees:
          values?.integrationType?.id === assessmentsIds.refapp
            ? parseInt(values?.referees?.value || values?.referees, 10)
            : null,
        refereeMailRequestMethodType:
          values?.integrationType?.id === assessmentsIds.refapp
            ? parseInt(values?.refereeMailRequestMethodType?.value || values?.refereeMailRequestMethodType, 10)
            : null,
        recipientUsers:
          values?.integrationType?.id === assessmentsIds.sri && values?.sriRecipientUserIds?.length
            ? values?.sriRecipientUserIds?.map(({ label, id }) => {
                return {
                  id,
                  email: label,
                };
              })
            : null,
        ordererUser:
          values?.integrationType?.id === assessmentsIds.sri
            ? { id: values?.sriOrdererUserId?.id, email: values?.sriOrdererUserId?.label }
            : null,
      };
    };
    const finalData = {
      ...values,
      assessment: getAssessmentValues(values),
    };

    await createOrUpdatePipelineStep(
      omit(finalData, [
        'integrationType',
        'assessmentType',
        'alvaLabsTestProfileId',
        'hubertInterviewTemplateId',
        'hubertThreshold',
        'refappQuestionerId',
        'refereeMailReferenceMethodType',
        'referees',
        'refereeMailRequestMethodType',
        'sriOrdererUserId',
        'sriRecipientUserIds',
        'sriOrdererUserEmail',
      ])
    );
    history.push('/settings/pipeline');
  };

  const isIntegrationDisabled =
    id &&
    type === 'edit' &&
    data?.integrationType &&
    (data?.assessmentType || data?.refappQuestionerId || data?.alvaLabsTestProfileId || data?.hubertInterviewTemplateId);

  if (loading) {
    return null;
  }

  return (
    <Layout title={t(`${capitalizeFirstLetter(type || 'Add')} Pipeline Step`)} pretitle={t('general.settings')}>
      <div className="anim-table-delayed">
        <Nav tabs>
          {pipelineStepTabs(t, hasAssessments).map((item, index) => (
            <NavItem key={item.id}>
              <NavLink
                className={classNames({ active: activeTab === index })}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Formik
          enableReinitialize
          initialValues={data}
          onSubmit={onSubmit}
          validationSchema={PipelineStepFormSchema(t)}
        >
          {({ handleSubmit, isSubmitting, values, errors }) => {
            const integrationId = values?.integrationType?.id || null;
            return (
              <form
                onSubmit={(e) => {
                  e.preventDefault();

                  handleSubmit();

                  if (Object.keys(errors)?.length) {
                    setActiveTab(0);
                  }
                }}
                className="anim-table-delayed max-w-2xl w-full shadow-sm p-4 bg-white rounded-lg mt-4"
              >
                {activeTab === 0 && (
                  <>
                    <div className="flex items-start gap-x-4">
                      <Field required name="name" label={t('general.name')} />
                    </div>
                    <Field name="instructionHtml" label={t('general.instruction')} type="wysiwyg" />
                    <Field name="instructionHtml" label={t('general.instruction')} type="wysiwyg" />
                  </>
                )}
                {activeTab === 1 && hasAssessments && (
                  <>
                    <Field
                      name="integrationType"
                      label={t('general.add-event')}
                      type="select"
                      defaultOptions={INTEGRATION_OPTIONS}
                      clearable
                      disabled={isIntegrationDisabled}
                    />
                    {integrationId === assessmentsIds.assessio && (
                      <Field
                        name="assessmentType"
                        label={t('general.tests')}
                        type="select"
                        defaultOptions={assessioOptions}
                        disabled={isIntegrationDisabled}
                      />
                    )}
                    {integrationId === assessmentsIds.refapp && (
                      <>
                        <Field
                          name="refappQuestionerId"
                          label={t('general.questioneer')}
                          type="select"
                          defaultOptions={REFAPP_QUESTIONNAIRES_OPTIONS}
                          disabled={isIntegrationDisabled}
                          keys={['id', 'label']}
                        />
                        <Field
                          name="refereeMailRequestMethodType"
                          label={t('settings.tests.ask-candidate-to-submit-referees-via')}
                          type="select"
                          defaultOptions={RefappAutomationMailOptions(t)}
                          searchable={false}
                          disabled={isIntegrationDisabled}
                        />
                        <Field
                          name="referees"
                          label={t('settings.tests.requested-referees')}
                          type="select"
                          defaultOptions={RefappRefereesOptions}
                          searchable={false}
                          disabled={isIntegrationDisabled}
                        />
                        <Field
                          name="refereeMailReferenceMethodType"
                          label={t('settings.tests.automatically-send-a-reference-check-via')}
                          type="select"
                          defaultOptions={RefappAutomationMailOptions(t)}
                          searchable={false}
                          disabled={isIntegrationDisabled}
                        />
                      </>
                    )}
                    {integrationId === assessmentsIds.alvaLabs && (
                      <Field
                        name="alvaLabsTestProfileId"
                        label={t('general.profiles')}
                        type="select"
                        defaultOptions={ALVA_LABS_PROFILE_OPTIONS}
                        disabled={isIntegrationDisabled}
                        keys={['id', 'label']}
                      />
                    )}
                    {integrationId === assessmentsIds.hubert && (
                      <>
                        <Field
                          name="hubertInterviewTemplateId"
                          label={t('general.interview-templates')}
                          type="select"
                          defaultOptions={HUBERT_INTERVIEW_TEMPLATE_OPTIONS}
                          disabled={isIntegrationDisabled}
                          keys={['id', 'label']} />
                        <Field
                          name="hubertThreshold"
                          label='Threshold'
                          disabled={isIntegrationDisabled} />
                      </>
                    )}
                    {integrationId === assessmentsIds.sri && (
                      <>
                        <Field
                          name="sriOrdererUserId"
                          label={t('general.orderer')}
                          type="select"
                          defaultOptions={SRI_ORDERERS_OPTIONS}
                          disabled={isIntegrationDisabled}
                        />
                        <Field
                          multiple
                          name="sriRecipientUserIds"
                          label={t('general.recipients')}
                          type="select"
                          defaultOptions={SRI_RECIPIENTS_OPTIONS}
                          disabled={isIntegrationDisabled}
                          keys={['id', 'label']}
                        />
                      </>
                    )}
                  </>
                )}
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  data-testid="form-submit"
                >
                  {t('general.submit')}
                </Button>
              </form>
            );
          }}
        </Formik>
      </div>
    </Layout>
  );
};

const PipelineStepFormWrapper = () => {
  const { id, type } = useParams();
  const { t } = useTranslation();
  const { findTest } = useAssessment();
  const REFAPP_QUESTIONNAIRES_OPTIONS = useSelector(selectRefappQuestionaires);
  const REFAPP_AUTOMATION_MAIL_OPTIONS = RefappAutomationMailOptions(t);
  const ALVA_LABS_PROFILE_OPTIONS = useSelector(selectAlvaLabsProfiles);
  const HUBERT_INTERVIEW_TEMPLATE_OPTIONS = useSelector(selectHubertInterviewTemplates);
  const SRI_RECIPIENTS_OPTIONS = useSelector(selectSriRecipients);
  const SRI_ORDERERS_OPTIONS = useSelector(selectSriOrderers);
  const AssessioTypeIntegration = [1, 2];

  const DEFAULT_PIPELINE_STEP_FORM = {
    name: '',
    instructionHtml: '',
    integrationType: null,
    assessmentType: null,
    refappQuestionerId: REFAPP_QUESTIONNAIRES_OPTIONS[0],
    hubertInterviewTemplateId: null,
    hubertThreshold: null,
    alvaLabsTestProfileId: null,
    refereeMailReferenceMethodType: RefappAutomationMailOptions(t)[0],
    referees: RefappRefereesOptions[2],
    refereeMailRequestMethodType: RefappAutomationMailOptions(t)[0],
    sriRecipientUserIds: null,
    sriOrdererUserId: null,
  };

  const getIntegrationValue = (value) => {
    return DefaultClientIntegration.find((integration) => {
      let assessmentType = value?.assessment?.type;
      if (AssessioTypeIntegration.includes(assessmentType)) assessmentType = 1;
      if (assessmentType && integration.id === assessmentType) {
        return {
          ...integration,
          value: integration.id,
        };
      }

      return null;
    });
  };

  const getMailOptionValue = (value) => {
    return value
      ? REFAPP_AUTOMATION_MAIL_OPTIONS.find((mailOption) => value?.toString() === mailOption?.value?.toString())
      : REFAPP_AUTOMATION_MAIL_OPTIONS[0];
  };

  return (
    <ApiRequestProvider
      withCancellation
      api={async (meta, source) => {
        if (type === 'edit' && id) {
          try {
            const response = await getPipelineStep(id, {
              cancelToken: source.token,
            });

            return {
              ...DEFAULT_PIPELINE_STEP_FORM,
              ...response,
              integrationType: getIntegrationValue(response),
              assessmentType: findTest(response?.assessment?.type),
              refappQuestionerId: response?.assessment?.refappQuestionerId
                ? REFAPP_QUESTIONNAIRES_OPTIONS.find((item) => item.id === response?.assessment?.refappQuestionerId)
                : REFAPP_QUESTIONNAIRES_OPTIONS[0],
              alvaLabsTestProfileId: response?.assessment?.alvaLabsTestProfileId
                ? ALVA_LABS_PROFILE_OPTIONS.find((item) => item.id === response?.assessment?.alvaLabsTestProfileId)
                : null,
              hubertInterviewTemplateId: response?.assessment?.hubertInterviewTemplateId
                ? HUBERT_INTERVIEW_TEMPLATE_OPTIONS.find((item) => item.id === response?.assessment?.hubertInterviewTemplateId)
                : null,
              hubertThreshold: response?.assessment?.hubertThreshold,
              refereeMailReferenceMethodType: getMailOptionValue(response?.assessment?.refereeMailReferenceMethodType),
              referees: response?.assessment?.referees
                ? RefappRefereesOptions.find((item) => {
                    return item.value === response?.assessment?.referees?.toString();
                  })
                : RefappRefereesOptions[2],
              refereeMailRequestMethodType: getMailOptionValue(response?.assessment?.refereeMailRequestMethodType),
              sriRecipientUserIds: response?.assessment?.sriRecipientUserIds
                ? SRI_RECIPIENTS_OPTIONS.filter((item) => response?.assessment?.sriRecipientUserIds?.includes(item.id))
                : null,
              sriOrdererUserId: response?.assessment?.sriOrdererUserId
                ? SRI_ORDERERS_OPTIONS.find((item) => {
                    return item.id === response?.assessment?.sriOrdererUserId;
                  })
                : null,
              sriOrdererUserEmail: response?.assessment?.sriOrdererUserEmail
                ? SRI_RECIPIENTS_OPTIONS.filter((item) => response?.assessment?.sriOrdererUserEmail?.includes(item.id))
                : null,
            };
          } catch (error) {
            return DEFAULT_PIPELINE_STEP_FORM;
          }
        }

        return DEFAULT_PIPELINE_STEP_FORM;
      }}
    >
      <PipelineStepForm />
    </ApiRequestProvider>
  );
};

export default PipelineStepFormWrapper;
