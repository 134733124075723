import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { get, set, isEmpty } from 'lodash';
import Form from '../form/Form';
import { pipelineStepTabs } from '../../constants/Pipeline';
import useAssessment from '../../hooks/useAssessment';
import {
  DefaultClientIntegration,
  RefappAutomationMailOptions,
  RefappRefereesOptions,
} from '../../constants/ClientIntegration';
import Assessments from '../../constants/Assessments';
import { useSelector as useSelectorToolkit } from '../../store';
import {
  selectAlvaLabsProfiles,
  selectRefappQuestionaires,
  selectSriRecipients,
  selectSriOrderers,
  selectOptions,
  selectHubertInterviewTemplates,
} from '../../store/selectors/settings.selector';
import { getIntegration } from '../../store/api/client-integrations.api';
import { log } from '../../helpers/utils';

const PipelineStepForm = ({
  draft = false,
  pipelineSteps: formPipelineSteps,
  buttonProps,
  onSubmit,
  isSending,
  form,
  maxWidth,
  card,
  namePrefix,
  resource,
  isPositionForm,
  isPositionTemplateForm,
  isSettingsForm,
  recipientsList,
}) => {
  const { t } = useTranslation();
  const { id, type } = useParams();
  const {
    hasAssessio,
    hasRefapp,
    hasAlvaLabs,
    hasHubert,
    hasAssessments,
    hasSri,
    assessmentsIds,
    options: testOptions,
  } = useAssessment();

  const [activeTab, setActiveTab] = useState(0);
  const alvaLabsOptions = useSelectorToolkit(selectAlvaLabsProfiles);
  const hubertOptions = useSelectorToolkit(selectHubertInterviewTemplates);
  const questioneerOptions = useSelectorToolkit(selectRefappQuestionaires);
  const recipientOptions = useSelectorToolkit(selectSriRecipients);
  const ordererOptions = useSelectorToolkit(selectSriOrderers);
  const options = useSelectorToolkit(selectOptions);

  const showAssessioOption = useMemo(() => {
    return (
      hasAssessio &&
      (formPipelineSteps || [])?.filter((item) => {
        const assessmentId = item?.assessmentType?.id || item?.assessmentType;

        return assessmentId === 1 || assessmentId === 2;
      }).length < testOptions.length
    );
  }, [formPipelineSteps, hasAssessio, testOptions]);

  const showAlvaOption = useMemo(() => {
    if (isPositionTemplateForm) return hasAlvaLabs;
    return hasAlvaLabs && !formPipelineSteps?.find((item) => !isEmpty(item.alvaLabsTestProfileId));
  }, [formPipelineSteps, hasAlvaLabs, isPositionTemplateForm]);

  const showHubertOption = useMemo(() => {
    if (isPositionTemplateForm) return hasHubert;
    return hasHubert && !formPipelineSteps?.find((item) => !isEmpty(item.hubertInterviewTemplateId));
  }, [formPipelineSteps, hasHubert, isPositionTemplateForm]);

  const showRefappOption = useMemo(() => {
    if (isPositionTemplateForm) return hasRefapp;
    return hasRefapp && !formPipelineSteps?.find((item) => !isEmpty(item.refappQuestionerId));
  }, [formPipelineSteps, hasRefapp, isPositionTemplateForm]);

  const showSRIOption = useMemo(() => {
    if (isPositionTemplateForm) return hasSri;
    return hasSri && !formPipelineSteps?.find((item) => !isEmpty(item.sriOrdererUserId?.id));
  }, [formPipelineSteps, hasSri, isPositionTemplateForm]);

  const syncedRecipientList = useMemo(() => {
    return options?.syncedRecipientList || null;
  }, [options.syncedRecipientList]);

  const clientIntegrationOptions = useMemo(() => {
    return DefaultClientIntegration.filter(
      ({ id }) =>
        (assessmentsIds.refapp === id && showRefappOption) ||
        (assessmentsIds.assessio === id && showAssessioOption) ||
        (assessmentsIds.alvaLabs === id && showAlvaOption) ||
        (assessmentsIds.hubert === id && showHubertOption) ||
        (assessmentsIds.sri === id && showSRIOption)
    ).map((item) => ({
      ...item,
      key: item.id,
      value: item.id,
    }));
  }, [DefaultClientIntegration, showRefappOption, showAssessioOption, showAlvaOption, showHubertOption, showSRIOption]);

  const checkIfDisabled = useMemo(() => {
    return (
      (id || type === 'edit') &&
      !form.isCreate &&
      !draft &&
      form.integrationType &&
      !isSettingsForm &&
      !isPositionTemplateForm &&
      (form.assessmentType && form.assessmentType !== Assessments.sri) &&
      (form.refappQuestionerId || form.alvaLabsTestProfileId || form.hubertInterviewTemplateId)
    );
  }, [
    id,
    type,
    form.isCreate,
    draft,
    form.integrationType,
    isSettingsForm,
    isPositionTemplateForm,
    form.assessmentType,
    form.refappQuestionerId,
    form.alvaLabsTestProfileId,
    form.hubertInterviewTemplateId,
    form.sriOrdererUserId,
  ]);
  const hasAssement =
    form.assessmentType ||
    (form.refappQuestionerId && !isEmpty(form.refappQuestionerId)) ||
    (form.alvaLabsTestProfileId && !isEmpty(form.refappQuestionerId)) ||
    (form.hubertInterviewTemplateId && !isEmpty(form.refappQuestionerId)) ||
    (form.sriOrdererUserId && form.sriOrdererUserId !== 'undefined');

  const [assessioConfig, setAssessionConfig] = useState([]);

  const assessioOptions = useMemo(() => {
    return testOptions.filter((test) => {
      const isActive = !!assessioConfig.find((config) => config.assessmentType === test?.id)?.isActive;

      const testType = formPipelineSteps.find((step) => {
        return test?.id === (step?.assessmentType?.id || step?.assessmentType || step?.assesment?.type);
      });

      return !testType && isActive;
    });
  }, [testOptions, formPipelineSteps, assessioConfig]);

  const getAssessioConfig = async () => {
    try {
      const response = await getIntegration('assessio');

      setAssessionConfig(response.configModel.settings);
    } catch (error) {
      log(error);
    }
  };

  useEffect(() => {
    if (!hasAssessio) {
      return;
    }

    getAssessioConfig();
  }, [hasAssessio]);

  const renderForm = useMemo(() => {
    return (
      <Form
        enableReinitialize={false}
        card={card}
        maxWidth={maxWidth}
        className="anim-table-delayed"
        onSubmit={onSubmit}
        defaultValues={{
          ...form,
          sriRecipientUserIds:
            form.sriRecipientUserIds && form.sriRecipientUserIds?.length && form.sriRecipientUserIds?.[0]?.id
              ? form.sriRecipientUserIds
              : (recipientsList || recipientOptions).filter((recipient) =>
                  (form?.sriRecipientUserIds || []).includes(recipient.id)
                ),
          sriOrdererUserEmail: form.sriOrdererUserEmail?.length
            ? (recipientsList || recipientOptions).filter((recipient) =>
                form?.sriOrdererUserEmail.includes(recipient.id)
              )
            : null,
        }}
        hasButton={() => {
          return (activeTab === 1 && !checkIfDisabled) || activeTab === 0;
        }}
        button={{
          loading: isSending,
          ...buttonProps,
          cancelProps: {
            ...buttonProps?.cancelProps,
            disabled: checkIfDisabled,
          },
        }}
        getErrorMessages={(errors) => {
          if (errors.name) {
            setActiveTab(0);
          }
        }}
        inputs={
          activeTab === 1 && hasAssessments
            ? [
                {
                  formGroup: {
                    className: 'hidden',
                  },
                  key: 'name',
                  name: `${namePrefix}-name`,
                  label: t('general.name'),
                  rules: {
                    required: true,
                  },
                },
                {
                  key: 'integrationType',
                  name: `${namePrefix}-integrationType`,
                  label: t('general.add-event'),
                  type: 'selectv2',
                  disabled: hasAssement,
                  options: clientIntegrationOptions,
                  dataTestId: 'position-integration-type',
                  clearable: true,
                  onSelect: ([{ type }], setForm, form) => {
                    if (type === 'Assessio') {
                      setForm(
                        set(
                          {
                            ...form,
                            refappQuestionerId: null,
                            assessmentType: null,
                          },
                          'assessmentType',
                          assessioOptions?.[0]
                        )
                      );
                    } else if (type === 'Refapp') {
                      setForm(
                        set(
                          {
                            ...form,
                            assessmentType: null,
                            alvaLabsTestProfileId: null,
                            hubertInterviewTemplateId: null,
                            hubertThreshold: null,
                          },
                          'refappQuestionerId',
                          questioneerOptions?.[0]
                        )
                      );
                    } else if (type === 'Alvalabs') {
                      setForm(
                        set(
                          {
                            ...form,
                            refappQuestionerId: null,
                            hubertInterviewTemplateId: null,
                            hubertThreshold: null,
                            assessmentType: null,
                          },
                          'alvaLabsTestProfileId',
                          alvaLabsOptions?.[0]
                        )
                      );
                    } else if (type === 'Hubert') {
                      setForm(
                        set(
                          {
                            ...form,
                            refappQuestionerId: null,
                            alvaLabsTestProfileId: null,
                            assessmentType: null,
                          },
                          'hubertInterviewTemplateId',
                          hubertOptions?.[0]
                        )
                      );
                    } else if (type === 'Sri') {
                      setForm(
                        set(
                          {
                            ...form,
                            alvaLabsTestProfileId: null,
                            hubertInterviewTemplateId: null,
                            hubertThreshold: null,
                            refappQuestionerId: null,
                            assessmentType: assessmentsIds.sri,
                          },
                          'sriOrdererUserId',
                          syncedRecipientList?.[0]
                        )
                      );
                    }
                  },
                },
                {
                  key: checkIfDisabled ? 'test' : 'assessmentType',
                  name: `${namePrefix}-${checkIfDisabled ? 'test' : 'assessmentType'}`,
                  label: t('general.tests'),
                  type: 'selectv2',
                  options: checkIfDisabled ? testOptions : assessioOptions,
                  rules: {
                    showFn: ({ integrationType }) => {
                      return get(integrationType, 'value') === assessmentsIds.assessio;
                    },
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-assessio',
                  keys: ['id', 'label'],
                },
                // refapp
                {
                  key: 'refappQuestionerId',
                  name: `${namePrefix}-refappQuestionerId`,
                  label: t('general.questioneer'),
                  type: 'selectv2',
                  options: questioneerOptions,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.refapp,
                    required: true,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-refapp',
                  keys: ['id', 'label'],
                },
                {
                  key: 'refereeMailRequestMethodType',
                  name: `${namePrefix}-refereeMailRequestMethodType`,
                  label: t('settings.tests.ask-candidate-to-submit-referees-via'),
                  type: 'selectv2',
                  options: RefappAutomationMailOptions(t),
                  isNotSearchable: true,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.refapp,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-refereeMailRequestMethodType',
                },
                {
                  key: 'referees',
                  name: `${namePrefix}-referees`,
                  label: t('settings.tests.requested-referees'),
                  type: 'selectv2',
                  options: RefappRefereesOptions,
                  isNotSearchable: true,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.refapp,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-referees',
                },
                {
                  key: 'refereeMailReferenceMethodType',
                  name: `${namePrefix}-refereeMailReferenceMethodType`,
                  label: t('settings.tests.automatically-send-a-reference-check-via'),
                  type: 'selectv2',
                  options: RefappAutomationMailOptions(t),
                  isNotSearchable: true,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.refapp,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-refereeMailReferenceMethodType',
                },
                // alvalabs
                {
                  key: 'alvaLabsTestProfileId',
                  name: `${namePrefix}-alvaLabsProfile`,
                  label: t('general.profiles'),
                  type: 'selectv2',
                  options: alvaLabsOptions,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.alvaLabs,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-alva-labs',
                  keys: ['id', 'label'],
                },
                // hubert
                {
                  key: 'hubertInterviewTemplateId',
                  name: `${namePrefix}-hubertInterviewTemplateId`,
                  label: t('general.interview-templates'),
                  type: 'selectv2',
                  options: hubertOptions,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.hubert,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-hubert-interview-template-id',
                  keys: ['id', 'label'],
                },
                {
                  key: 'hubertThreshold',
                  name: `${namePrefix}-hubertThreshold`,
                  label: 'Threshold',
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.hubert,
                  },
                  disabled: checkIfDisabled,
                  dataTestId: 'position-hubert-threshold',
                },
                // sri
                {
                  key: 'sriOrdererUserId',
                  name: `${namePrefix}-sriOrdererUserId`,
                  label: t('general.orderer'),
                  type: 'selectv2',
                  options: ordererOptions,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.sri,
                    required: isPositionForm,
                  },
                  dataTestId: 'position-sri-orderer-user-id',
                },
                {
                  key: 'sriRecipientUserIds',
                  name: `${namePrefix}-sriRecipientUserIds`,
                  label: t('general.recipients'),
                  type: 'selectv2',
                  options: recipientsList || recipientOptions,
                  multiple: true,
                  rules: {
                    showFn: ({ integrationType }) => get(integrationType, 'value') === assessmentsIds.sri,
                  },
                  dataTestId: 'position-sri-recipient-user-ids',
                  keys: ['id', 'label'],
                },
              ]
            : [
                {
                  key: 'name',
                  name: `${namePrefix}-name`,
                  label: t('general.name'),
                  rules: {
                    required: true,
                  },
                  dataTestId: 'position-name-input',
                },
                {
                  key: 'test',
                  name: `${namePrefix}-test`,
                  label: t('general.test'),
                  type: 'selectv2',
                  options: assessioOptions,
                  hide:
                    !testOptions.length || isPositionForm || isPositionTemplateForm || isSettingsForm || !hasAssessio,
                  dataTestId: 'position-test-select',
                },
                {
                  key: 'instructionHtml',
                  name: 'pipeline-steps',
                  label: t('general.instruction'),
                  type: 'wysiwyg',
                  editorName: 'instruction',
                  dataTestId: 'position-instruction-html',
                },
              ]
        }
        resourceName={`${resource}--pipeline-step`}
      />
    );
  }, [activeTab, hasAssessments, form]);

  return (
    <>
      <div className="user-profile-form anim-table-delayed !mb-4">
        <Nav tabs>
          {pipelineStepTabs(t, hasAssessments).map((item, index) => (
            <NavItem key={item.id}>
              <NavLink
                className={classNames({ active: activeTab === index })}
                onClick={() => {
                  setActiveTab(index);
                }}
              >
                {item.label}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
      {renderForm}
    </>
  );
};

PipelineStepForm.defaultProps = {
  card: true,
  buttonProps: {},
  form: {},
  maxWidth: null,
  namePrefix: '',
  resource: 'position',
  pipelinesSelected: [],
  index: 1,
  recipientsList: null,
};

export default PipelineStepForm;
