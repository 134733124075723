import { AxiosResponse } from 'axios';
import ClientIntegrationResponseList from '@ts/apicontracts/clientIntegrations/list/response';
import ClientIntegrationListItem from '@ts/apicontracts/clientIntegrations/list/list-item';
import ClientIntegrationDetailsResponse from '@ts/apicontracts/clientIntegrations/details/response';
import RefappResponseList from '@ts/apicontracts/clientIntegrations/refapp/response';
import AlvalabsResponseList from '@ts/apicontracts/clientIntegrations/alvalabs/response';
import HubertResponseList from '@ts/apicontracts/clientIntegrations/alvalabs/hubert';
import SriRecipientsResponseList from '@ts/apicontracts/clientIntegrations/sriRecipients/response';
import SriOrderersResponseList from '@ts/apicontracts/clientIntegrations/sriOrderers/response';
import { api } from '../../helpers/api-v2';

const resource = '/client-integrations';
const marketingResource = '/client-marketing-integrations';

export const getRefappQuestioneers = async () => {
  const response = await api.get(`${resource}/refapp/questioner-list`);
  return response as AxiosResponse<RefappResponseList>;
};

export const getAlvaLabsProfiles = async () => {
  const response = await api.get(`${resource}/alvalabs/test-profile-list`);
  return response as AxiosResponse<AlvalabsResponseList>;
};

export const getHubertInterviewTemplates = async () => {
  const response = await api.get(`${resource}/hubert/interview-templates-list`);
  return response as AxiosResponse<HubertResponseList>;
};

export const getSriRecipients = async () => {
  const { value }: any = await api.get(`${resource}/sri/recipients-list`);
  return value as AxiosResponse<SriRecipientsResponseList>;
};

export const getSriOrderers = async () => {
  const { value }: any = await api.get(`${resource}/sri/orderers-list`);
  return value as AxiosResponse<SriOrderersResponseList>;
};

export const addOrUpdateIntegration = async (values, options = {}) => {
  const response = await api.post(resource, values, options);
  return response;
};

export const getIntegrations = async (options = {}) => {
  const response = await api.get(`${resource}/list`, options);
  return response as AxiosResponse<ClientIntegrationResponseList>;
};

export const getIntegration = async (id: number, options = {}) => {
  const response = await api.get(`${resource}/${id}`, options);
  return response as AxiosResponse<ClientIntegrationListItem>;
};

export const getAlvaLabsConfig = async () => {
  try {
    const data = await api.get(`${resource}/alvalabs`);
    return data as AxiosResponse<ClientIntegrationDetailsResponse>;
  } catch (error) {
    throw error;
  }
};

export const getHubertConfig = async () => {
  try {
    const data = await api.get(`${resource}/hubert`);
    return data as AxiosResponse<ClientIntegrationDetailsResponse>;
  } catch (error) {
    throw error;
  }
};

export const toggleIntegrationsActive = async (type, value) => {
  try {
    const data = api.post(`${resource}/${type}/${value}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAmsGroups = async () => {
  const amsGroups = await api.get(`${marketingResource}/ams`);
  return amsGroups as unknown as { municipalities: object[]; occupationTree: object[] };
};

export const deleteIntegrationResource = async (integration) => {
  return api.delete(`${resource}`, { data: { type: integration } });
};
