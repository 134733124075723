/* eslint-disable no-case-declarations */
export const types = {
  Application: 0,
  PipelineStep: 1,
  Rejected: 2,
  Unrejected: 3,
  Comment: 4,
  MessageToCandidate: 5,
  MessageFromCandidate: 6,
  Rating: 7,
  ScreeningQuestionBool: 8,
  ScreeningQuestionInt: 9,
  ScreeningQuestionSelection: 10,
  ApplicationManual: 11,
  ApplicationImported: 12,
  DelayedMessage: 13,
  AutoRejected: 14,
  Edited: 15,
  AssignedRecruiter: 16,
  Talent: 17,
  UserNotified: 18,
  MessageToCandidateAutomatic: 19,
  MovedCandidateToOtherPosition: 20,
  MovedAutomaticallyAfterTestResult: 21,
  RejectedAutomaticallyAfterTestResult: 22,
  ScheduleInterviewCandidate: 23,
  AcceptedInterviewCandidate: 24,
  MessageToCandidateByOtherUser: 25,
  AssessmentSendToCandidate: 26,
  CandidateUploadedVideo: 27,
  SRIRequestEmailSendToCandidate: 28,
  SRIRequestFilledByCandidate: 29,
  SRIRequestStateUpdate: 30,
  RefappAssessmentFailed: 31,
  HubertInterviewScoreUpdate: 32,
};

const formatAssessmentType = (type) => {
  switch (type) {
    case global?.appConfig?.assessment_type_1:
      return 'Mint';
    case global?.appConfig?.assessment_type_2:
      return 'Service First';
    case global?.appConfig?.assessment_type_100:
      return 'Refapp';
    case global?.appConfig?.assessment_type_200:
      return 'Alva Labs';
    default:
      return type;
  }
};

const formatSRIStatus = (type, t) => {
  switch (type) {
    case 1:
      return t('status.created');
    case 2:
      return t('status.pending-consent');
    case 3:
      return t('status.declined');
    case 4:
      return t('status.in-progress');
    case 5:
      return t('status.pending-approval');
    case 6:
      return t('status.approved');
    default:
      return '';
  }
};

export const getLabel = (candidate, t) => {
  switch (candidate.type) {
    case types.ScheduleInterviewCandidate:
      return {
        icon: 'microphone',
        tr: t('candidates.historyentry-name.has-been-invited-to-an-interview', {
          name: candidate.name,
          userName: candidate.userName,
        }),
      };

    case types.AcceptedInterviewCandidate:
      return {
        icon: 'microphone',
        tr: t('candidates.historyentry-name.accepted-the-invitation', {
          name: candidate.name,
        }),
      };

    case types.Application:
      return {
        icon: 'folders',
        tr: t('candidates.historyentry-name.application-received'),
      };

    case types.ApplicationManual:
      return {
        icon: 'brackets',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-registered-candidate-manually'
        )}`,
      };

    case types.ApplicationImported:
      return {
        icon: 'file-import',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-imported-candidate'
        )}`,
      };

    case types.PipelineStep:
      return {
        icon: 'stream',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-moved-candidate-to-space'
        )} ${candidate.extraParameter}`,
      };

    case types.Rejected:
      return {
        icon: 'thumbs-down',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-rejected-candidate'
        )}`,
      };

    case types.Unrejected:
      return {
        icon: 'thumbs-up',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-set-candidate-as-not-rejected'
        )}`,
      };

    case types.AutoRejected:
      return {
        icon: 'thumbs-down',
        tr: t(
          'candidates.historyentry-name.candidate-was-rejected-automatically'
        ),
      };

    case types.Comment:
      return {
        icon: 'comment-alt-dots',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-commented'
        )}`,
      };

    case types.MessageToCandidate:
      return {
        icon: 'inbox-out',
        // candidate userName sent email to current candidate
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-sent-email-to-space'
        )} ${candidate.activeCandidate}`,
      };

    case types.MessageFromCandidate:
      return {
        icon: 'inbox-in',
        tr: `${candidate.extraParameter} ${t(
          'candidates.historyentry-name.space-replied-by-email'
        )}`,
      };

    case types.Rating:
      const eventType = candidate.extraParameter;
      const extraValues = eventType && eventType.split('|');
      if (eventType === 'deleted') {
        return {
          icon: 'star',
          tr: `${candidate.userName} ${t(
            'candidates.historyentry-name.space-removed-hisher-rating'
          )}`,
        };
      }
      if (eventType && eventType.includes('modified')) {
        return {
          icon: 'star',
          tr: `${candidate.userName} ${t(
            `candidates.historyentry-name.space-${
              extraValues &&
              extraValues[1] &&
              parseInt(extraValues[1], 10) !== 0
                ? 'changed-hisher-rating-from'
                : 'rated-the-candidate-with'
            }-space`,
            {
              x: extraValues.length && extraValues[1] ? extraValues[1] : 0,
              y: extraValues.length && extraValues[2] ? extraValues[2] : 0,
            }
          )} `,
        };
      }
      return {
        icon: 'star',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-rated-the-candidate-with-space'
        )} ${extraValues[1]}`,
      };

    case types.ScreeningQuestionBool:
      const value = candidate.extraParameter ? 'Yes' : 'No';
      return {
        icon: 'user',
        tr: t('candidates.historyentry-name.space-specified-question-x-as-y', {
          x: candidate.extraText,
          y: value,
        }),
      };

    case types.ScreeningQuestionInt:
      return {
        icon: 'user',
        tr: t('candidates.historyentry-name.space-specified-question-x-as-y', {
          x: candidate.extraText,
          y: candidate.extraParameter,
        }),
      };

    case types.ScreeningQuestionSelection:
      let sel;
      for (
        let i = 0;
        i < candidate.statebag.screeningQuestions.length;
        i += 1
      ) {
        sel = candidate.statebag.screeningQuestions[i].selections.find(
          (scc) => scc.screeningQuestionSelectionId === candidate.valueInt
        );
        if (sel != null) {
          break;
        }
      }

      return {
        icon: 'user',
        tr: t('candidates.historyentry-name.space-specified-question-x-as-y', {
          x: candidate.extraText,
          y: sel.name,
        }),
      };

    case types.DelayedMessage:
      return {
        icon: 'inbox-in',
        tr: t('candidates.historyentry-name.a-delayed-response-is-scheduled'),
      };

    case types.Edited:
      return {
        icon: 'pen',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-edited-the-candidate-details'
        )}`,
      };

    case types.AssignedRecruiter:
      let recruiterName = 'candidates.a-deleted-user'.toLowerCase();
      if (candidate.activeCandidate) {
        recruiterName = `${candidate.activeCandidate}`;
      }
      return {
        icon: 'user',
        tr: t(
          'candidates.historyentry-name.space-assigned-the-candidate-to-x',
          { x: recruiterName }
        ),
      };

    case types.Talent:
      return {
        icon: 'gem',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-moved-the-candidate-to-space'
        )}`,
      };
    case types.UserNotified:
      return {
        icon: 'user',
        tr: `${candidate.userName} ${t(
          'candidates.historyentry-name.space-got-email-that-candidate-applied'
        )}`,
      };
    case types.MessageToCandidateAutomatic:
      return {
        icon: 'inbox-in',
        tr: `${t(
          'candidates.historyentry-name.email-was-sent-automatically-to-space'
        )} ${candidate.activeCandidate}`,
      };

    case types.MovedCandidateToOtherPosition:
      const event = candidate.extraParameter;
      const eventValues = event && event.split('|');

      let translationText =
        'candidates.historyentry-name.space-moved-the-candidate-to-the-pos';
      let mover = eventValues.length > 1 ? eventValues[1] : candidate.userName;
      let newPosition = eventValues.length > 1 ? eventValues[2] : '';

      if (event) {
        if (event === 'moved-other-noaccess') {
          translationText =
            'candidates.historyentry-name.space-moved-the-candidate-to-another';
        }

        if (event.includes('moved-other')) {
          translationText =
            'candidates.historyentry-name.space-moved-the-candidate-to-position-x';
          mover = candidate.userName;
          newPosition = eventValues.length > 1 ? eventValues[1] : '';
        }
      }

      return {
        icon: 'user',
        tr: t(translationText, { x: mover, y: newPosition }),
      };

    case types.MovedAutomaticallyAfterTestResult:
      const moveEvent = candidate.extraParameter;
      const moveEventValues = moveEvent && moveEvent.split('|');

      return {
        icon: 'user',
        tr: `${candidate.activeCandidate} ${t(
          'candidates.historyentry-name.space-was-moved-automatically-to-x-based-on-the-test-result-from-y',
          {
            x:
              moveEventValues.length && moveEventValues[0]
                ? moveEventValues[0]
                : '',
            y:
              moveEventValues.length && moveEventValues[1]
                ? moveEventValues[1]
                : '',
          }
        )}`,
      };
    case types.RejectedAutomaticallyAfterTestResult:
      return {
        icon: 'user',
        tr: `${candidate.activeCandidate} ${t(
          'candidates.historyentry-name.space-was-rejected-automatically-based-on-the-test-result-from-y',
          { y: candidate.extraParameter }
        )}`,
      };

    case types.MessageToCandidateByOtherUser:
      return {
        icon: 'inbox-in',
        tr: t(
          `candidates.historyentry-name.send-email-to-candidate${
            candidate.extraParameter ? '-by-user' : ''
          }`,
          {
            sender: candidate.userName,
            candidate: candidate.activeCandidate,
            userName: candidate.extraParameter,
          }
        ),
      };

    case types.AssessmentSendToCandidate:
      return {
        icon: 'inbox-in',
        tr: t(
          `candidates.historyentry-name.${
            candidate.extraParameter === global?.appConfig?.assessment_type_100
              ? 'candidate-sent-to'
              : 'tests-have-been-sent-with'
          }`,
          {
            x: formatAssessmentType(candidate.extraParameter) || '',
          }
        ),
      };
    case types.CandidateUploadedVideo:
      return {
        icon: 'folder',
        tr: t('candidates.historyentry-name.candidate-uploaded-video'),
      };

    case types.SRIRequestEmailSendToCandidate:
      return {
        icon: 'inbox-in',
        tr: t(
          'candidates.historyentry-name.email-sent-to-candidate-with-public-form',
          {
            x: candidate.userName || '',
          }
        ),
      };

    case types.SRIRequestFilledByCandidate:
      return {
        icon: 'user',
        tr: t('candidates.historyentry-name.candidate-filled-in-public-form'),
      };
    case types.SRIRequestStateUpdate:
      const x = formatSRIStatus(parseInt(candidate.extraText, 10), t);
      return {
        icon: 'inbox-in',
        tr: t(
          'candidates.historyentry-name.sri-assessment-status-has-changed',
          {
            x,
          }
        ),
      };

    case types.RefappAssessmentFailed:
      return {
        icon: 'exclamation',
        tr: t('candidates.historyentry-name.refapp-assessment-failed'),
      };

    case types.HubertInterviewScoreUpdate:
      return {
        icon: 'inbox-in',
        tr: t(
          'candidates.historyentry-name.hubert-assessment-status-has-changed',
          {
            x: candidate.extraParameter
          }
        ),
      };

    default:
      return {
        icon: 'folder',
        tr: '',
      };
  }
};
