import { isNumber } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputRange from 'react-input-range';
import { CreateIntegrationsHrsOptions } from '../../../constants/ClientIntegration';
import Divider from '../Divider/Divider';
import Field from '../Form/Field';

const HubertConfig = ({ form, setFieldValue }) => {
  const { t } = useTranslation();
  const HRS_OPTIONS = CreateIntegrationsHrsOptions(t);
  const [sliderValue, setSliderValue] = useState({
    min: isNumber(form?.yellowScoreLowerBoundary)
      ? form?.yellowScoreLowerBoundary
      : 1,
    max: isNumber(form?.greenScoreLowerBoundary)
      ? form?.greenScoreLowerBoundary
      : 1,
  });

  return (
    <div className="form mt-4">
      <div className="pr-2 pl-2 mb-2 flex items-center justify-between relative">
        <div
          style={{
            width: `${sliderValue.min * 10}%`,
            height: 5,
            background: '#FF4365',
            zIndex: 40,
            transition: 'width 0.25s linear',
          }}
          className="relative"
        />
        <div
          className="absolute w-100"
          style={{
            marginLeft: -5,
            marginRight: -5,
          }}
        >
          <InputRange
            maxValue={10}
            minValue={0}
            value={sliderValue}
            onChange={(value) => {
              setSliderValue(value);
              setFieldValue('assessmentConfig', {
                ...form,
                yellowScoreLowerBoundary: value?.min || 0,
                greenScoreLowerBoundary: value?.max || 10,
              });
            }}
          />
        </div>
        <div
          style={{
            width: `${(10 - sliderValue.max) * 10}%`,
            height: 5,
            background: '#7ED58F',
            transition: 'width 0.25s linear',
          }}
          className="z-40 relative"
        />
      </div>
      <div className="mb-2 flex items-center justify-between mt-3">
        <p
          style={{
            width: `${sliderValue.min * 10}%`,
            right: sliderValue.min === 0 ? 5 : -5,
            transition: 'width 0.25s linear',
          }}
          className="text-right relative text-primary font-semibold whitespace-nowrap"
        >
          {sliderValue.min * 10}%
        </p>
        <p
          style={{
            width: `${(10 - sliderValue.max) * 10}%`,
            left: sliderValue.max === 10 ? -10 : -5,
            transition: 'width 0.25s linear',
          }}
          className="relative text-primary font-semibold whitespace-nowrap"
        >
          {sliderValue.max * 10}%
        </p>
      </div>
      <Divider>{t('settings.integrations.acceptance-threshold')}</Divider>
      <Field
        name="assessmentConfig.autoMoveGreenCandidatesToNextStep"
        label={t('settings.tests.move-automatically-for-green')}
        type="switch"
      />
      <Field
        name="assessmentConfig.autoMoveYellowCandidatesToNextStep"
        label={t('settings.tests.move-automatically-for-yellow')}
        type="switch"
      />
      <Field
        name="assessmentConfig.autoRejectRedCandidates"
        label={t('settings.tests.reject-automatically-for-red')}
        type="switch"
      />
    </div>
  );
};

export default HubertConfig;
